@media (min-width: map-get($breakpoints, lg) + 1px) {

  // Header - Centred Logo
  .pageHeader .headerContent .mainCallToAction {
    justify-content: space-between;
    a.mainLogo {
      order: 2;
      margin-right: 0;
    }
    ul.socialIcons {
      order: 1;
      margin-right: 0;
    }
    .mainCallToActionButtons {
      order: 3;
      width: 225px;
    }
  }

  // Carousel
  .carousel:hover .carouselSlideDetail {
    background-color: $white;
    transition: 300ms all ease;

    &:after {
      transition: 300ms all ease;
      background-color: $_weird-green;
      transform: translate(-50%, -50%) rotate(3deg);
    }
  }

  .carouselDetailWrapper { z-index: 1; }
  .carouselSlideDetail {
    position: relative;
    transition: 300ms all ease;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(1deg);
      width: 100%;
      height: 100%;
      background-color: rgba($_weird-green, 0);
      transition: 300ms all ease;
      z-index: -1;
      border-radius: $border-radius;
    }
  }


  // Homepage stats - background image
  .homefeaturecategory-homeboximpactstats:before {
    background-attachment: fixed;
  }

}

// On smaller screens
@media (max-width: map-get($breakpoints, lg)) {

  // Donate + Burger button - same width
  .headerContent .cta-button.donate {
    min-width: 91.06px;
  }

  // Social Icons - colour
  .menuMain .mainCallToAction .socialIcons a:before {
    color: $white;
  }
}

// Navigation - Border
nav.menuMain {
  border-top: 1.5px solid $white;
}

// Logo - Mobile
a.mainLogo {
  margin-right: 0;
}

// Burger button - hover state
.menuMainAlt {
  color: $white;
  background-color: transparent;
}
.menuMainAlt:hover, .menuMainAlt.active {
  color: $white;
  background-color: darken($header-background-colour, 10%);
}

// Home Intro - background image
.homeIntroWrapper:before {
  background-size: 185px;
}

// Card - layout
.homeFeatures .homeFeature, .feedItem, .listedPost {
  overflow: visible !important;
  position: relative;
  transition: 300ms all ease;
  z-index: 1;

  // Border radius
  .homeFeatureDetailsWrapper, .feedItemDetailsWrapper, .listedPostText {
    border-radius: 0 0 $border-radius $border-radius;
  }

  // Pseudo element
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(2deg);
    width: 100%;
    height: 100%;
    transition: 300ms all ease;
    z-index: -1;
    border-radius: $border-radius;
  }

  // Pseudo element - Hover
  &:hover:after {
    transform: translate(-50%, -50%) rotate(3.25deg);
  }
}

// Colour specific - Pseudo element
.homeBox1 {
  &:after { background-color: rgba(lighten($_dark-green, 25%), 0.725); }
  &:hover:after { background-color: lighten($_dark-green, 25%); }
}

.homeBox2 {
  &:after { background-color: rgba(lighten($_orange, 25%), 0.725); }
  &:hover:after { background-color: lighten($_orange, 25%); }
}

.homeBox3 {
  &:after { background-color: rgba(lighten($_pink, 25%), 0.725); }
  &:hover:after { background-color: lighten($_pink, 25%); }
}

.homeFeedBox1 .feedItem {
  &:after { background-color: rgba($_dark-green, 0.725); }
  &:hover:after { background-color: $_dark-green; }
}

.homeFeedBox2 .feedItem {
  &:after { background-color: rgba($_darkest-green, 0.725); }
  &:hover:after { background-color: $_darkest-green; }
}

.homeFeedBox3 .feedItem {
  &:after { background-color: rgba($_darkest-green, 0.725); }
  &:hover:after { background-color: $_darkest-green; }
}

.listedPost {
  &:after { background-color: rgba($_dark-green, 0.725); }
  &:hover:after { background-color: $_dark-green; }
}

// Home Quick Giving
.homepage-quickgiving--copy h2 {
  text-align: center;
  margin-bottom: $spacer * 2;
}

.homepage-quickgiving--copy .formQuestion.donationAmount {
  box-shadow: inset 0 0 0 1.5px $body-colour;
  strong { font-family: $headings-font-family; }
}

// Home Feeds - Background color
.homeFeedBox1 {
  background-color: $_light-green;
}

.homeFeedBox3 {
  background-color: $_light-green;
}

// Home Feeds - Event float date
.homeFeedevents p.associatedStartDate * {
  font-family: $headings-font-family;
}

// Newsletter - Border
.footerBox[class*=ewsletter] {
  border-bottom: 1.5px solid $white;
}

// Listing page - Layout
.listing .contentBlockWrapper {
  margin-bottom: 0;
}

// Sidebar - Layout
.associated {
  position: relative;
  border-radius: $border-radius;
  padding: $spacer;
  border: .5px solid $grey-lighter;
  background-color: $white;
  li { border: 0 !important; }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(2deg);
    width: 100%;
    height: 100%;
    background-color: $_light-green;
    transition: 300ms all ease;
    z-index: -1;
    border-radius: $border-radius;
  }
}

// Fundraising stuff - Remove on appeal listing pages
.appealListingPage .donorsListWrapper,
.appealListingPage .tabsWrapper {
  display: none;
}

// Mailchimp newsletter - Set up
@media (min-width: map-get($breakpoints, lg) + 1px) {
  .footerBox[class*="ewsletter"] #mc_embed_signup > form.validate {
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 $site-bleed;
    #mc_embed_signup_scroll {
      display: flex;
      flex-wrap: wrap;

      > h2 {
        width: 100%;
        font-size: $font-size-h2;
      }

      .indicates-required { width: 100%; margin: 0; }
      .asterisk {
        font-size: 1rem;
        color: $white;
      }
      > .mc-field-group:not(.input-group) {
        width: 33.33%;
        padding-bottom: $spacer;
      }
      > .mc-field-group:nth-of-type(3) { padding: 0 15px; }

      .mc-field-group.input-group {
        width: 80%;
        ul, ul li { display: inline-block; }
        ul li { margin-left: 15px; }
        input { margin-right: 5px; }
      }

      #mc-embedded-subscribe {
        padding: $btn-padding-y $btn-padding-x;
        background-color: $_orange;
        height: auto;
        font-size: 1rem;
        &:hover { background-color: darken($_orange, 10%); }
      }
    }
  }
}

// Old styles - Runover
@mixin button($bg-colour: $button-colour) {
	background-color: $bg-colour;
	border-radius: $border-radius;
	color: text-contrast($bg-colour);
	padding: $btn-padding-y $btn-padding-x;
	margin: 0 10px 10px 0;
  font-size: 1rem;
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
  transition: all $btn-transition-duration;

	&:hover{
		background-color: darken($bg-colour, 10%);
		text-decoration: none;
    color: text-contrast(darken($bg-colour, 10%));
	}
}
.button--green { @include button($bg-colour: $_dark-green); }
.button--green-light { @include button($bg-colour: $_weird-green); }
.button--green-lime { @include button($bg-colour: $_light-green); }
.button--orange { @include button($bg-colour: $_orange); }
.button--pink { @include button($bg-colour: $_pink); }
.button--corporate { @include button($bg-colour: #009483); }
.button--weddings { @include button($bg-colour: #8CD4C0); }


#mc_embed_signup #mce-success-response {
  color: #fff !important;
}


// Create Blog post section
body[class*='blog'] .formQuestion.postCreateContent {
	textarea#postCreateContent {
		min-height:  300px;
	}
}
body[class*='blog'] .postCreateLocation {
	display: none;
}


// Social Media Landing page
body.PostCategory_social-media-landing-page {

  // Hide all unnecessary items
  nav.menuMain,
  .menuAdminContainer,
  footer.pageFooterWrapper,
  .mainCallToAction *:not(.mainLogo),
  .header-search,
  footer.postFooterWrapper,
  aside.postAside,
  header.headerWrapper,
  a.buttonExit,
  a.menuMainAlt,
  aside.postComments {
    display: none;
  }

  .mainCallToAction {
    justify-content: center;
  }

  // Header Tweak
  .headerContent {
    max-width: 720px;
    margin: 0 auto;
    padding: 0;
  }

  // Edit ainLogo
  a.mainLogo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 185px;
    height: 90px;
    // margin: 0 auto;
  }

  // Content layout
  .contentBlock .postContent {
    width: 100%;
    padding: 0;
    max-width: 720px;
    margin: 1rem auto 0;
    float: none;
    text-align: center;

    // Inner buttons
    a[class*="cta-button"],
    a[class*="button"] {
      display: block;
      width: 100%;
      position: relative;
      padding: 1rem 2rem;
      margin: 0 auto;

      @media (max-width: 768px) {
        margin: 0.5rem auto;
        padding: 0.75rem 1.5rem;
      }
    }
  }
}

// Fix quick giving
body:not(.homepage) {
  .quickGivingPanel .formQuestion.donationAmount.donationSelected {
    color: #fff;
  }
  .contentBlock .quickGivingPanel .donationAmount:not(.donationSelected) .donationAmountDescription {
    color: $grey;
  }
}

// Subsite customisations https://raisingit.atlassian.net/browse/IM-944
body.subsite.trees-for-climate {
  #h1SubsiteTitle {
    display: none;
  }
  .contentContainer .headerWrapper.headerWrapperSubsite {
    order: 1;
  }
  #menuSub {
    background-color: $_dark-green;
  }
}

.subsite #h1SubsiteTitle {
  display: none;
}

// 410710
// Category to hide event dates on listed posts and individual events with category

section.PostCategory_multiple-event-dates footer .postFooter { 

  p.associatedStartDate {
    &:after {
      content: 'Multiple event dates';
    }
    span {
      display: none;
    }
  }
  p.associatedEndDate {
    display: none!important;
  }
}

body.PostCategory_multiple-event-dates {

  section.eventWhenWrapper p:before {
    content: 'Multiple event dates';
  }

  section.eventWhenWrapper p:first-of-type strong {
    display:none;
  }

  section.eventWhenWrapper p:first-of-type time {
    display:none;
  }

  section.eventWhenWrapper p:last-of-type {
    display: none;
  }
}

@media screen and (min-width: 1272px){
  ul.topLevel {
    max-width: 1440px;
  }
}