// Config values
$platform-name: "marstonvale";
$assets-path: "../assets/";

// Colours
// $_darkest-green: #38500E;
$_darkest-green: #546223;
$_dark-green: #619116;
// $_weird-green: #3AB133;
$_weird-green: #43b02a;
$_light-green: #CDD601;
$_orange: #F58022;
$_pink: #EE2F80;
$_blue: #00A6DC;

$brand-primary: $_light-green;
$brand-primary-text-contrast-override: #FFF;
$brand-secondary: $_dark-green;
$brand-secondary-text-contrast-override: #FFF;

$donate-colour: $_pink;
// $donate-colour: $_darkest-green;

// Create greyscale
$black: #000;
$grey-dark: #111;
$grey: #333;
$grey-light: #636c72;
$grey-lighter: #eceeef;
$grey-lightest: #F6F6F6;
$white: #fff;

// Layout
$container-max-width: 1230px;
$gap-width: 40px;
$site-bleed: 20px;

$border-radius: 6px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey;
$profile-pics-enabled: true;
$border-width: .5px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.3s;
$transition-curve: ease;

// Logo
$logo: "logo-white.svg";
// $logo-retina: $logo;
$logo-width: 290px;
$logo-height: 130px;
// $logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, lg);
$logo-mobile: $logo;
// $logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.75;
$logo-mobile-height: $logo-height * 0.75;

// Typography
$font-family-base: 'Poppins', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: normal;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Poppins', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $_dark-green;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $_dark-green;
$btn-text-colour: $white;
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $_dark-green;
$btn-box-shadow: 0;
$btn-padding-x: 1.45em;
$btn-padding-y: 0.725em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-normal;
$btn-font-size: inherit;
$btn-text-transform: none;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: $white;

// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $_orange;
$social-icons-hover-colour: darken($_orange, 10%);
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.2rem;
$social-icons-gap: 0;
$social-icons-border-radius: 0;
$social-icons-box-shadow: 0;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $white;
$social-icons-header-hover-colour: $_orange;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_orange;

// Share this page
$share-enabled: true;
$share-text-align: left;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

//
// Tables
//

$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: 0;
$card-box-shadow: 0;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: $border-radius $border-radius 0 0;
$card-details-padding: $spacer * 1.5;
$card-details-background-colour: $white;
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $body-colour;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: $body-colour;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: .5rem $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;

// Card text over
$card-text-over-image-vertical-text-position: center;
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true;
$card-side-by-side-vertical-text-position: top;
$card-side-by-side-breakpoint: map-get($breakpoints, sm);

// Card hover state
$card-hover-border-colour: null;
$card-hover-box-shadow: 0;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient;
$card-hover-heading-colour: $_dark-green;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: $_darkest-green;
$event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer;
$event-card-floating-date-margin-x: -$spacer;

// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;

// Header
// $header-background-colour: $_light-green;
$header-background-colour: $_weird-green;
$header-background-gradient: none;
$header-box-shadow: 0;

// $header-absolute: false; // true if you want the header to appear transparently over banner images
// $header-absolute-background: linear-gradient(
//   to bottom,
//   rgba($black, 0.5),
//   rgba($black, 0)
// ); // Colour or gradient
// $header-absolute-breakpoint: map-get(
//   $breakpoints,
//   sm
// ); // When do you want to abandon the absolute header and return to solid?

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer * 0.5;
$header-content-padding-bottom: $spacer * 0.5;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: false;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
// $tagline-above-cta-breakpoint: 0;
// $tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $brand-secondary;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 10px;
// $cta-padding-right-at-nav-breakpoint: 6.5em; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search
$header-search-enabled: false;
// $header-search-margin-right: 20px;
// $header-search-breakpoint: map-get($breakpoints, lg);
//
// // Search - input
// $header-search-input-max-width: 200px;
// $header-search-input-height: initial; // If you need to specificy a specific height here
// $header-search-input-margin-right: 8px;
// $header-search-input-padding: $input-padding-y $input-padding-x;
// $header-search-input-placeholder-colour: $input-placeholder-colour;
// $header-search-input-border-radius: $border-radius;
// $header-search-input-border-colour: $border-colour;
// $header-search-input-border-width: 1px 1px 1px 1px; // t-r-b-l
// $header-search-input-background-colour: $white;
// $header-search-input-colour: text-contrast($header-search-input-background-colour);
// $header-search-input-focus-background-colour: $white;
// $header-search-input-focus-colour: text-contrast(
//   $header-search-input-focus-background-colour
// );
//
// // Search - button
// $header-search-button-width: 40px;
// $header-search-button-height: 40px;
// $header-search-button-background-colour: $btn-background-colour;
// $header-search-button-icon-font-size: $social-icons-font-size;
// $header-search-button-icon-colour: text-contrast(
//   $header-search-button-background-colour
// );
// $header-search-button-border-radius: $border-radius;
//
// // Search when in nav
// $nav-search-enabled: $header-search-enabled;
// $nav-search-input-margin-right: 1rem;
// $nav-search-input-padding: $input-padding-y $input-padding-x;
// $nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $nav-search-input-border-colour: $header-search-input-border-colour;
// $nav-search-input-border-width: 0; // Should already contrast enough with the menu bg
// $nav-search-input-background-colour: $header-search-input-background-colour;
// $nav-search-button-background-colour: $header-search-button-background-colour;
// $nav-search-button-icon-colour: text-contrast(
//   $nav-search-button-background-colour
// );

// Social icons
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $_weird-green;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-normal;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $white;
$nav-top-level-item-hover-background-colour: darken($_weird-green, 10%);
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: darken($_weird-green, 10%);
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;
$nav-submenu-item-hover-colour: $white;
$nav-submenu-item-hover-background-colour: rgba($black, 0.1);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $white;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $white;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: null;

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $_weird-green;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.1);
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $white;

// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour;
// $no-banner-logo: $logo;
// $no-banner-logo-retina: $no-banner-logo;
// $no-banner-tagline-colour: $tagline-colour;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: rgba($black, 0.15);
$carousel-contents-max-width: $container-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-background-colour: rgba($white, .55);
$carousel-details-box-shadow: 0;
$carousel-details-max-width: 580px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 1.5 $spacer * 1.5 $spacer * 1.5 $spacer * 3 ;
$carousel-details-margin-y: $spacer * 2;
$carousel-details-margin-x: 0;
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $body-colour;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: $body-colour;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: false;
$carousel-read-more-background-colour: $_dark-green;
$carousel-read-more-border-colour: $_dark-green;
$carousel-read-more-colour: $btn-text-colour;
$carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 5px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: 0;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-y: $spacer;
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.2em;
$carousel-controls-next-icon: "\f054";
$carousel-controls-prev-icon: "\f053";

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
$carousel-buttons-type: dots; // dots or tabs

// Carousel buttons dots
// $carousel-dots-position-y: bottom; // top, bottom
// $carousel-dots-position-x: center; // left, center, right
// $carousel-dots-padding: $spacer;
// $carousel-dots-colour: $white;
// $carousel-dots-opacity: 0.5;
// $carousel-dots-active-colour: $brand-primary;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%; // What percentage do you want the play button positioned vertically?
$carousel-video-btn-position-x: 50%; // What percentage do you want the play button positioned laterally?

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
$carousel-scroll-btn-size: $carousel-controls-button-size;
$carousel-scroll-btn-background-colour: $btn-background-colour;
$carousel-scroll-btn-border-radius: $border-radius;
$carousel-scroll-btn-box-shadow: $box-shadow;
$carousel-scroll-btn-icon-colour: $btn-text-colour;
$carousel-scroll-btn-icon-font-size: 1em;
// $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
$carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
$carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover"; // 'cover' or 'contain'
$carousel-yt-cover-padding-bottom: 56.25%; // Play with this to insure you get proper coverage. This is a dark art, see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed. I'm giving you control here to play around with it.

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $_weird-green;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Banner min-height
$banner-min-height: 200px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
$home-intro-background-image: "home-intro-icon.svg";
$home-intro-background-image-opacity: 1;
$home-intro-box-shadow: $box-shadow;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 4.5;
// $home-intro-margin-top: $spacer * 3;
$home-intro-margin-top: 0;
// $home-intro-margin-bottom: $spacer * 3;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-h2;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: $border-radius;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $grey-lightest;
$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 1.5;
$home-features-cards-entry-animation-prefab: 0; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3 !default

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: $spacer;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 6;
$impact-stats-background-colour: $black;
$impact-stats-background-gradient: none; // none or CSS gradient
$impact-stats-background-image: "RectoryWood-youngtrees-059.png";
$impact-stats-background-image-opacity: 0.8;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center; // left, center, right
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get(
  $breakpoints,
  md
); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 1.5;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: $spacer * 1.5 0 0;
$impact-stat-margin: 0;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: $spacer * 0.5;

// Home feeds
$home-feeds-max-width: $container-max-width;
// $home-feeds-background-colour: $white;
$home-feeds-background-colour: $grey-lightest;
$home-feeds-padding-top: $spacer * 2.5;
$home-feeds-padding-bottom: $spacer * 2.5;
$home-feeds-cards-entry-animation-prefab: 0; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Twitter feed
// $home-feed-twitter-enabled: false;
// $home-feed-twitter-card-gap-width: $card-gap-width;
// $home-feed-twitter-card-border: $card-border;
// $home-feed-twitter-card-border-radius: $card-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow;
// $home-feed-twitter-card-text-align: $card-text-align;
// $home-feed-twitter-card-padding: $card-details-padding;
// $home-feed-twitter-card-background-colour: $card-details-background-colour;
// $home-feed-twitter-card-colour: text-contrast(
//   $home-feed-twitter-card-background-colour
// );
// $home-feed-twitter-card-min-width: $card-min-width;

// Footer
$footer-boxes-count: 3;
$footer-prefab: 1;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 4;
$footer-background-colour: $_weird-green;
// $footer-background-gradient: none; // none or CSS gradient
$footer-colour: $white;
$footer-link-colour: $white;
$footer-link-decoration: none;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: left;
$footer-font-size: $font-size-base;
$footer-headings-font-size: $font-size-h2;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $_weird-green;
$newsletter-background-gradient: none; // none or CSS gradient
// $newsletter-colour: $body-colour;
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $_orange;
$newsletter-button-colour: $white;
$newsletter-button-border-colour: $_orange;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 1.5;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;

// Posts
$post-content-max-width: 1230px;
$post-body-background-colour: transparent;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $body-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * .8;
$page-title-sm-font-size: $page-title-font-size * .6;

// Sidebar
$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: adjacent; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 4;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 4;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-summary-enabled: true;
$associated-item-border: null;

// Listing
$listing-content-max-width: 1440px;
$listing-intro-max-width: 1440px;
$listing-body-background-colour: $grey-lightest;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: false;
$listed-blog-post-publish-date-enabled: false;
$listed-blog-post-author-enabled: false;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: false;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align; // left, center or right

// Blockquote
$blockquote-max-width: $post-content-max-width; // Either a px value or 100%
$blockquote-background-colour: $_dark-green;
$blockquote-background-gradient: none; // none or CSS gradient
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 3;
$blockquote-padding: $spacer * 1.5;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: 1.25em; // Em unit will cascade to children
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $white;
$blockquote-box-shadow: 0;

// Fundraising
$fundraising-enabled: false; // Quickly turn off fundraising features

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: false;
$find-a-fundraiser-profile-pics-enabled: false;

// Top fundraisers
$top-fundraisers-enabled: false;
$top-fundraisers-profile-pics-enabled: false;

// Donation form
$donation-form-layout: columns; // rows or columns
$donation-form-banner-enabled: false;
$donation-form-frequency-tabs: true;

// Donation amounts
$donation-amount-min-width: $card-min-width; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: columns; // simple, columns or rows
$quick-giving-background-colour: $white;
$quick-giving-background-gradient: none; // none or CSS gradient
$quick-giving-padding: $context-container-padding 0;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size *
  0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: #fff;
$quick-giving-donation-amount-figure-colour: $_pink;
$quick-giving-donation-amount-border-width: 0px;
$quick-giving-donation-amount-border-colour: $body-colour;
$quick-giving-donation-amount-selected-background-colour: $_pink;
$quick-giving-donation-amount-selected-figure-colour: $body-colour;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 4.5;
$home-quick-giving-background-colour: $white;
$home-quick-giving-donation-amount-background-colour: $donate-colour;
$home-quick-giving-donation-amount-figure-colour: $white;
$home-quick-giving-donation-amount-border-width: 0px;
$home-quick-giving-donation-amount-border-colour: $body-colour;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: $btn-border-colour;
// $home-quick-giving-donate-btn-font-size: $font-size-large;
$home-quick-giving-colour: $body-colour; // This should cover any headings and text you have in the snippet
$home-quick-giving-heading-colour: $headings-colour;
$home-quick-giving-heading-font-size: $font-size-h2;

// Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image
//
// // Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(
//   to left,
//   rgba(0, 0, 0, 0) 40%,
//   rgba(0, 0, 0, 0.75) 100%
// );

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal; // vertical or horizontal
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: $profile-pics-enabled;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $context-container-background-colour;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $btn-background-colour;
$faq-item-btn-chevron-colour: $btn-text-colour;
// $faq-item-hover-btn-background-colour: button-hover-state(
//   $faq-item-btn-background-colour
// );
// $faq-item-hover-btn-chevron-colour: text-contrast(
//   $faq-item-hover-btn-background-colour
// );

// In Memory
$listing-inmem-masonry-layout: false;

// Shop
$shop-enabled: true;

$basket-link-only-on-shop-pages: false;

// Departments list
$department-list-width: $sidebar-adjacent-width;
$department-list-background-colour: $grey-lightest;
$department-list-padding: $context-container-padding;
$department-list-border-radius: $context-container-border-radius;

// Product-price
$product-price-colour: $_orange;
$product-price-font-size: $font-size-h3;

// Product post
$product-post-content-max-width: $post-content-max-width;

// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false !default;
$subsite-hide-main-tagline: false !default;
$subsite-hide-header-search: false !default;
$subsite-hide-main-cta: false !default;
$subsite-content-max-width: $container-max-width !default;
$subsite-title-font-size: $page-title-font-size !default;

// Subsite logo
$subsite-logo: "logo.svg" !default; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
$subsite-logo-retina: $subsite-logo !default;
$subsite-logo-width: 120px !default;
$subsite-logo-height: 120px !default;
$subsite-logo-margin: $header-content-margin-top 0 0 !default;

// Subsite nav
$subsite-nav-breakpoint: $nav-breakpoint !default;
$subsite-nav-max-width: $nav-normal-max-width !default;
$subsite-nav-margin-top: $spacer !default;
$subsite-nav-margin-bottom: $spacer !default;
$subsite-nav-background-colour: $nav-background-colour !default;
$subsite-nav-contents-max-width: $container-max-width !default;
$subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
$subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
$subsite-nav-top-level-item-colour: $nav-top-level-item-colour !default;
$subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
$subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
$subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
$subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
$subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
$subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

$subsite-nav-submenu-width: $nav-submenu-width !default;
$subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
$subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
$subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
$subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
$subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
$subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
$subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
$subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
$subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
$subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: $black;
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: $_light-green;
$cookie-consent-btn-text-colour: $white;

// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;
